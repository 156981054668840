import React from 'react'

import { Container } from 'components/common/styles'

import { CardImage, Details, FinalPosition, GraphContainer, InfoText, ItemContainer, SectionTitle } from './styles'
import CardSVG from 'assets/images/comcard.svg'

const InfoSection = () => {
  return (
    <section>
      <Container>
        <Details>
          <ItemContainer style={{ gridArea: '2 / 1 / 3 / 2' }}>
            <SectionTitle>Say goodbye to fees</SectionTitle>
            <InfoText>
              We don’t believe in unnecessary fees that traditional corporate cards tack on.
            </InfoText>
          </ItemContainer>
          <ItemContainer style={{ gridArea: '3 / 1 / 4 / 2' }}>
            <SectionTitle>Tailored financial management tools</SectionTitle>
            <InfoText>
              Seamless integration with banking and accounting apps, and other features.
            </InfoText>

          </ItemContainer>
          <FinalPosition>
            <GraphContainer>

              <svg viewBox="0 0 487 487" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.25" cx="243" cy="243" r="192" fill="#6BCBC4" />
                <circle className="outer" opacity="0.45" cx="243.5" cy="243.5" r="243" stroke="#00A095"
                        strokeLinejoin="round" strokeDasharray="8 8" />
              </svg>
              <CardImage src={CardSVG} />
            </GraphContainer>
          </FinalPosition>
          <ItemContainer style={{ gridArea: '2 / 3 / 3 / 4' }}>
            <SectionTitle>No personal guarantee required</SectionTitle>
            <InfoText>
              Get quick access to credit and other ComCard benefits by connecting your business checking account, with
              no hard pull on your personal credit.
            </InfoText>

          </ItemContainer>
          <ItemContainer style={{ gridArea: '3 / 3 / 4 / 4' }}>
            <SectionTitle>Top rewards that make sense</SectionTitle>
            <InfoText>
              Double-digit cash back and rewards from preferred vendors, suppliers and retailers.
            </InfoText>
          </ItemContainer>
        </Details>
      </Container>
    </section>
  )
}

export default InfoSection
