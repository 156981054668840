import styled from '@emotion/styled'
import { BlockTitle, Text } from 'components/common/styles'

const BaseBlock = styled.div`
  padding: 30px 0;
`

export const Image = styled.img`
  max-width: 100%;
`

export const QualifyBlock = styled(BaseBlock)`
  padding-top: 60px;
  padding-bottom: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 768px) {
    flex-direction: row-reverse;
  }
`

export const QualifyImageContainer = styled.div`
  padding-bottom: 40px;
  flex: 1.5;
  text-align: center;
  @media screen and (min-width: 768px) {
    padding-bottom: 0;
  }
`
export const QualifyImage = styled(Image)`
  width: 60%;

  @media screen and (min-width: 768px) {
    width: 70%;
  }

  @media screen and (min-width: 1024px) {
    width: 50%;
  }
`

export const QualifyContent = styled.div`
  flex: 1;
`

export const QualifyTitle = styled(BlockTitle)`
  margin-bottom: 24px;
`

export const QualifyDescription = styled(Text)`
  margin-bottom: 24px;

  @media screen and (min-width: 768px) {
    margin-bottom: 40px;
  }
`
