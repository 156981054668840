import React, { useState } from 'react'
import Layout from 'layouts/default'

import InfoSection from 'components/pages/cards/info'
import Features from 'components/pages/cards/features'
import IntegrationSection from 'components/pages/cards/integration'
import richSnippet from 'components/common/rich-snippet'
import { createCardSchema } from 'utils/richSnippets'
import Modal from 'components/common/modal'
import Waitlist from 'components/common/modal/content/waitlist'
import Hero from 'components/hero'
import Button, { ButtonSize } from 'components/common/button'
import Image from 'assets/images/card-page/hero.jpg'
import QualifySection from 'components/qualify'

const CardsPage: React.FC = props => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  React.useEffect(() => {
    window.analytics.page('Our Cards')
  }, [])
  return (
    <Layout title='Our Cards | ComCard' helmetChildren={richSnippet(createCardSchema())}>
      <Modal isOpen={isModalOpen} title="Join waitlist" onClose={() => setIsModalOpen(false)}>
        <Waitlist closeModal={() => setIsModalOpen(false)} />
      </Modal>
      <Hero
        title="Finally, a business card designed for your industry and your needs."
        subTitle={<Button size={ButtonSize.MEDIUM} onClick={() => {
          setIsModalOpen(true)
          window.analytics.track('Open the waitlist modal', {
            location: 'Cards Hero',
          })
        }}>Join Waitlist</Button>}
        image={Image}
        zIndex={10}
      />
      <InfoSection />
      <Features />
      <IntegrationSection bottomSpacing={60} />
      {/*<GuarantiesSection />*/}
      {/*<CashBackSection/>*/}
      <QualifySection />

    </Layout>
  )
}

export default CardsPage
