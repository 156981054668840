import * as React from 'react'
import styled from '@emotion/styled'
import { Container } from 'components/common/styles'
import { between } from 'polished'

type Props = {
  title: string
  subTitle?: string | React.ReactNode
  image: string
  zIndex?: number
};

const AdaptiveContainer = styled(Container)`
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

type ContentProps = {
  bg: string
  zIndex?: number
}
const Content = styled.div<ContentProps>`
  background-size: cover;
  background-position: center center;
  background-image: url("${props => props.bg}");
  border-radius: 60px;
  padding: 5% 25% 10% 110px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  min-height: 500px;
  
  > * {
    position: relative;
    z-index: ${props => props.zIndex};
  }
  
  @media screen and (max-width: 1300px) {
    min-height: ${between('300px', '500px', '768px', '1300px')};
  }
  
  @media screen and (max-width: 768px) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: auto;
    padding: 120px 17px;
  }
`

const Title = styled.h1`
  font-size: 40px;
  line-height: 1.25;
  font-weight: bold;
  color: #ffffff;
  
  text-shadow: 0 2px 11px rgba(27, 29, 40, 0.6);
  
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`
const SubTitle = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #F9F8F7;
  font-weight: 500;
  margin-top: 32px;
  
  text-shadow: 0 2px 11px rgba(27, 29, 40, 1);
`

const Hero: React.FC<Props> = ({ title, subTitle, image, zIndex = 1 }) => {
  return (
    <AdaptiveContainer>
      <Content bg={image} zIndex={zIndex}>
        <Title>{title}</Title>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
      </Content>
    </AdaptiveContainer>
  )
}

export default Hero
