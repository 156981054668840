import React from 'react'

import { Text } from 'components/common/styles'

import {
  BlockContainer,
  CashbackLocatorContainer,
  Content,
  CustomAlertsContainer,
  EmptySpacing,
  FilledSpacing,
  FraudControlContainer,
  ImageContainer,
  InfoContainer,
  ItemContainer,
  MissionControlDashboardContainer,
  ReceiptManagementContainer,
  SubTitle,
  Title,
  VirtualCardsContainer,
} from './styles'
import CashbackLocatorPNG from 'assets/images/card-page/features/cashback_locator.png'
import CustomAlertsPNG from 'assets/images/card-page/features/custom_alerts.png'
import VirtualCardsPNG from 'assets/images/card-page/features/virtual_cards.png'
import ReceiptManagementPNG from 'assets/images/card-page/features/receipt_management.png'
import FraudControlPNG from 'assets/images/card-page/features/fraud.jpg'
import MissionControlPNG from 'assets/images/card-page/features/dashboard.png'

const Features = () => {
  const [activeTop, setActiveTop] = React.useState(1)
  const [activeBottom, setActiveBottom] = React.useState(1)
  return (
    <>
      <Title>Features that make day-to-day business easier</Title>
      <Content position="left">
        <FilledSpacing />
        <BlockContainer position="left">
          <ImageContainer position="left">
            <MissionControlDashboardContainer isOpen={activeTop == 1}>
              <img src={MissionControlPNG} alt="Mission control dashboard art" />
            </MissionControlDashboardContainer>
            <ReceiptManagementContainer isOpen={activeTop == 2}>
              <img src={ReceiptManagementPNG} alt="Receipt management art" />
            </ReceiptManagementContainer>
            <FraudControlContainer isOpen={activeTop == 3}>
              <img src={FraudControlPNG} alt="Receipt management art" />
            </FraudControlContainer>
          </ImageContainer>
          <InfoContainer position="left">
            <ItemContainer withBorder={activeTop == 1} onClick={() => setActiveTop(1)}>
              <SubTitle>Mission control dashboard</SubTitle>
              <Text>
                Track expenses by project, manage employee cards and spending, and stop worrying about receipts.
              </Text>
            </ItemContainer>
            <ItemContainer withBorder={activeTop == 2} onClick={() => setActiveTop(2)}>
              <SubTitle>Receipt Manager 2.0</SubTitle>
              <Text>
                Rest easy knowing purchases are tracked, assigned and accounted for properly.
              </Text>
            </ItemContainer>
            <ItemContainer withBorder={activeTop == 3} onClick={() => setActiveTop(3)}>
              <SubTitle>Fraud control</SubTitle>
              <Text>
                Deactivate a card with just one click, and feel confident with complete coverage on any unauthorized
                purchase.
              </Text>
            </ItemContainer>
          </InfoContainer>
        </BlockContainer>
        <EmptySpacing />
      </Content>
      <Content position="right">
        <EmptySpacing />
        <BlockContainer position="right">
          <InfoContainer position="right">
            <ItemContainer withBorder={activeBottom == 1} onClick={() => setActiveBottom(1)}>
              <SubTitle>Virtual cards</SubTitle>
              <Text>
                Our virtual cards eliminate the chances of losing cards or having them stolen.
              </Text>
            </ItemContainer>
            <ItemContainer withBorder={activeBottom == 2} onClick={() => setActiveBottom(2)}>
              <SubTitle>Cashback Locator</SubTitle>
              <Text>
                Wherever your business takes you, our cashback locator will direct you to the best rewards and cashback
                in your area.
              </Text>
            </ItemContainer>
            <ItemContainer withBorder={activeBottom == 3} onClick={() => setActiveBottom(3)}>
              <SubTitle>Custom alerts</SubTitle>
              <Text>
                Customized email and text alerts ensure you’re always aware of activity on your account.
              </Text>
            </ItemContainer>
          </InfoContainer>
          <ImageContainer position="right">
            <VirtualCardsContainer isOpen={activeBottom == 1}>
              <img src={VirtualCardsPNG} alt="Virtual cards art" />
            </VirtualCardsContainer>
            <CashbackLocatorContainer isOpen={activeBottom == 2}>
              <img src={CashbackLocatorPNG} alt="Cashback Locator App" />
            </CashbackLocatorContainer>
            <CustomAlertsContainer isOpen={activeBottom == 3}>
              <img src={CustomAlertsPNG} alt="Custom alert notification on iPhone" />
            </CustomAlertsContainer>
          </ImageContainer>
        </BlockContainer>
        <FilledSpacing />
      </Content>
    </>
  )
}

export default Features
