import React from 'react'

import {
  Circles,
  ComCardLogo,
  Content,
  ContentText,
  Dot,
  Dots,
  GraphContainer,
  InsideIconsContainer,
  LocalContainer,
  OutsideIconsContainer,
  PurchaseContainer,
  PurchaseIcon,
  QBLogo,
  ReceiptIcon,
  ReceiptsIcon,
  TagIcon,
  Title,
  ToolsIcon,
} from './styles'

import ComCardLogoIcon from 'assets/images/card-page/comcard.svg'
import QBLogoIcon from 'assets/images/card-page/qb.svg'
import ToolsIconSVG from 'assets/images/card-page/tools.svg'
import ReceiptIconSVG from 'assets/images/card-page/receipt.svg'
import ReceiptsIconSVG from 'assets/images/card-page/receipts.svg'
import TagIconSVG from 'assets/images/card-page/tag.svg'
import PurchaseIconSVG from 'assets/images/card-page/purchase.svg'
import styled from '@emotion/styled'

const Image = styled.img`
  height: .3em;
`

type Props = {
  bottomSpacing?: number
}

const IntegrationSection: React.FC<Props> = ({ bottomSpacing }) => {
  return (
    <LocalContainer bottomSpacing={bottomSpacing}>
      <GraphContainer>
        <Circles>
          <svg viewBox="0 0 434 434" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.45" cx="217" cy="217" r="152.5" stroke="#00A095" strokeLinejoin="round"
                    strokeDasharray="8 8" />
            <circle opacity="0.45" cx="217" cy="217" r="216.5" stroke="#00A095" strokeLinejoin="round"
                    strokeDasharray="8 8" />
          </svg>
        </Circles>
        <ComCardLogo src={ComCardLogoIcon} />
        <QBLogo src={QBLogoIcon} />
        <OutsideIconsContainer>
          <ReceiptsIcon>
            <div>
              <Image src={ReceiptsIconSVG} />
            </div>
          </ReceiptsIcon>
          <PurchaseIcon>
            <div>
              <Image src={PurchaseIconSVG} />
            </div>
          </PurchaseIcon>
          <ReceiptIcon>
            <div>
              <Image src={ReceiptIconSVG} />
            </div>
          </ReceiptIcon>
        </OutsideIconsContainer>
        <InsideIconsContainer>
          <TagIcon>
            <div>
              <Image src={TagIconSVG} />
            </div>
          </TagIcon>
          <ToolsIcon>
            <div>
              <Image src={ToolsIconSVG} />
            </div>
          </ToolsIcon>
        </InsideIconsContainer>
        <PurchaseContainer>
          <span>auto sync</span>
          <Dots>
            <Dot />
            <Dot />
            <Dot />
            <Dot />
            <Dot />
            <Dot />
            <Dot />
          </Dots>
        </PurchaseContainer>
      </GraphContainer>
      <Content>
        <Title>QuickBooks integration</Title>
        <ContentText>
          Automatically sync your card expenses with QuickBooks.
          This process lets you seamlessly manage and take control
          of receipt and expense reconciliation, and
          stay current on your reporting at all times.
        </ContentText>
      </Content>
    </LocalContainer>
  )
}

export default IntegrationSection
