import styled from '@emotion/styled'

export const Container = styled.section`
  order: 2;
`

type ContentProps = {
  position: 'left' | 'right'
}

export const Content = styled.div<ContentProps>`
  display: grid;
  grid-template-columns: 1fr minmax(0, 1480px) 1fr;
  grid-template-rows: 1fr;
  flex-direction: row;
  align-self: stretch;
  
  margin-bottom: 120px;
  
  @media screen and (max-width: 900px) {
    flex-direction: column;
    
    :first-of-type {
      flex-direction: column-reverse;
    }
    
    :not(:first-of-type) {
      margin-top: 0;
    }
  }
  
  @media screen and (max-width: 768px) {
    margin-bottom: 60px;
  }
`

export const FilledSpacing = styled.div`
  background-color: #E4F1EF;
`

type BlockContainerProps = {
  position: 'left' | 'right'
}

export const BlockContainer = styled.div<BlockContainerProps>`
  display: flex;
  flex-direction: row;
  
  //padding-right: ${props => props.position === 'left' ? '100px' : 0};
  //padding-left: ${props => props.position === 'right' ? '100px' : 0};
  
  @media screen and (max-width: 768px) {
    flex-direction: ${props => props.position === 'left' ? 'column-reverse' : 'column'};
  }
`
export const EmptySpacing = styled.div``


type ImageContainerProps = {
  position: 'left' | 'right'
}

export const ImageContainer = styled.div<ImageContainerProps>`
  position: relative;
  width: 45%;
  flex-grow: 1;
  //padding-left: ${props => props.position === 'left' && '100px'};
  min-height: 500px;
  background: #e4f1ef;
  border-top-right-radius: ${props => props.position === 'left' && '60px'};
  border-bottom-right-radius: ${props => props.position === 'left' && '60px'};
  border-top-left-radius: ${props => props.position === 'right' && '60px'};
  border-bottom-left-radius: ${props => props.position === 'right' && '60px'};
  
  display: flex;
  flex-direction: row;
  align-items: center;
  
  @media screen and (max-width: 1280px) {
    width: 500px;
  }
  
  @media screen and (max-width: 900px) {  
    width: auto;
    height: 386px;
    margin-top: 32px;
  }
  
  @media screen and (max-width: 768px) {
     margin-right: ${props => props.position === 'left' && '17px'};
    margin-left: ${props => props.position === 'right' && '17px'};
  }
`

export const Title = styled.h2`
  padding-left: 24px;
  font-size: 34px;
  font-weight: bold;
  line-height: 50px;
  
  margin-bottom: 30px;
  margin-top: 20px;
  
  @media screen and (max-width: 900px) { 
     font-size: 30px;
     margin-top: initial;
  }
`

export const SubTitle = styled.h3`
  margin-bottom: 16px;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
`
type InfoContainer = {
  position: 'left' | 'right'
}
export const InfoContainer = styled.div<InfoContainer>`
  width: 55%;
 
  padding-right: ${props => props.position === 'right' ? '150px' : 0};
  padding-left: ${props => props.position === 'left' ? '150px' : 0};
   
  @media screen and (max-width: 1280px) {
    padding-right: ${props => props.position === 'right' ? '50px' : 0};
    padding-left: ${props => props.position === 'left' ? '50px' : 0};
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 0;
  }
`

type ItemContainerProps = {
  withBorder?: boolean
}

export const ItemContainer = styled.div<ItemContainerProps>`
  margin-top: 24px;
  padding: 24px;
  border-radius: 20px;
  background: ${props => props.withBorder && '#E4F1EF'};
  transition: background-color ease-in-out 200ms;
  
  cursor: pointer;
`

export const ScreenImage = styled.img`
   position: relative;
   right: -70px;
   width: 100%;
   
   @media screen and (max-width: 900px) { 
     right: -40px;
     width: 390px;
     height: 250px;
     
     max-width: calc(100% - 40px);
  }
`

export const PhoneImage = styled.img`
    position: absolute;
    top: 63px;
    left: 50%;
    width: 255px;
    transform: translateX(-50%);
    
     @media screen and (max-width: 900px) { 
     width: 194px;
     height: 385px;
  }
`

type FeatureArtProps = {
  isOpen: boolean
}

const FeatureArt = styled.div<FeatureArtProps>`
  display: ${props => props.isOpen ? 'flex' : 'none'};
  width: 100%;
  height: 100%;
`

export const MissionControlDashboardContainer = styled(FeatureArt)`
  flex-direction: row;
  align-items: center;
  img {
    width: 90%;
  }
  
  @media screen and (max-width: 900px) {
    img {
      max-width: 50vw;
      margin-right: 20px;
    }
  }
  
  @media screen and (max-width: 768px) {
    img {
      width: 90%;
      max-width: 100%;
    }
  }
`

export const ReceiptManagementContainer = styled(FeatureArt)`
  flex-direction: row;
  align-items: center;
  img {
    width: 90%;
  }
  
  @media screen and (max-width: 900px) {
    img {
      max-width: 50vw;
      margin-right: 20px;
    }
  }
  
  @media screen and (max-width: 768px) {
    img {
      width: 90%;
      max-width: 100%;
    }
  }
`

export const FraudControlContainer = styled(FeatureArt)`
  img {
    position: absolute;
    max-height: 80%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`

export const VirtualCardsContainer = styled(FeatureArt)`
  flex-direction: row;
  align-items: center;
  img {
    max-width: 100%;
  }
  
  @media screen and (max-width: 900px) {
    img {
      max-width: 50vw;
    }
  }
  
  @media screen and (max-width: 768px) {
    img {
      max-width: 100vw;
    }
  }
`

export const CashbackLocatorContainer = styled(FeatureArt)`
  img {
    position: absolute;
    max-height: 105%;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const CustomAlertsContainer = styled(FeatureArt)`
  img {
    position: absolute;
    max-height: 105%;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
`
