import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { Container, Text } from 'components/common/styles'
import { between } from 'polished'

type LocalContainerProps = {
  bottomSpacing?: number
}

export const LocalContainer = styled(Container)<LocalContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ bottomSpacing = 120 }) => bottomSpacing}px;
  
  @media screen and (max-width: 768px) {
    margin-bottom: 60px;
    flex-direction: column;
  }
`

// GRAPH

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const GraphContainer = styled.div`
  flex-shrink: 0;
  font-size: 100px;
  
  width: 4.34em;
  height: 4.34em;
  
  position: relative;
  
  margin-right: 250px;
  margin-left: .7em;
  
  @media screen and (max-width: 1280px) {
    font-size: ${between('60px', '100px', '768px', '1280px')};
    margin-right: 150px;
  }
  
  @media screen and (max-width: 768px) {
    font-size: calc((80vw - 30px) / 4.34 / 1.5);
    margin-bottom: 50px;
    align-self: center;
    margin-right: .7em;
  }
`
export const Circles = styled.div`
  width: 100%;
  height: 100%;
  
  svg {
    width: 100%;
    height: 100%;
    
    circle {
      animation: ${rotation} linear 60s infinite;
      transform-origin: center center;
    }
  }
`
export const ComCardLogo = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  width: 2.13em;
  
  transform: translateY(-63%) translateX(-35%);
`
export const QBLogo = styled.img`
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 2;
  width: 2.62em;
  
  transform: translateY(-60%) translateX(45%);
`
export const OutsideIconsContainer = styled.div``
export const InsideIconsContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  
  width: 3.044em;
  height: 3.044em;
  
  transform: translateX(-50%) translateY(-50%);
`
export const PurchaseContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  
  transform: translateX(-50%) translateY(calc(-50% - .24em));
  
  display: flex;
  flex-direction: column;
  align-items: center;
  
  span {
    display: block;
    font-size: 0.18em;
    line-height: 1.5;
    margin-bottom: 0.05em;
    font-weight: 500;
  }
`
export const Dots = styled.div`
  height: 0.11em;
  
  display: flex;
  flex-direction: row;
  align-items: center;
`

const DotAnimation = keyframes`
  0% {
    transform: scale(.3);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.3);
  }
`

export const Dot = styled.div`
  flex-shrink: 0;
  width: .11em;
  height: .11em;
  background-color: ${props => props.theme.colors.green100};
  animation: ${DotAnimation} ease-in-out 1.5s infinite;
  border-radius: 50%;
  margin: 0 .07em;
  transform: scale(.3);
  
  &:nth-of-type(1) {
    animation-delay:0ms;
    opacity: 0.1;
  }
  &:nth-of-type(2) {
    animation-delay:100ms;
    opacity: 0.4;
  }
  &:nth-of-type(3) {
    animation-delay:200ms;
    opacity: 0.8;
  }
  &:nth-of-type(4) {
    animation-delay:300ms;
    opacity: 1;
  }
  &:nth-of-type(5) {
    animation-delay:400ms;
    opacity: 0.8;
  }
  &:nth-of-type(6) {
    animation-delay:500ms;
    opacity: 0.4;
  }
  &:nth-of-type(7) {
    animation-delay:500ms;
    opacity: 0.1;
  }
`

// ICONS

const AbstractIcon = styled.div`
  width: 100%;
  height: 100%;
  
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  position: absolute;
  top: 0;
  left: 0;
  
  opacity: 0;
  
  z-index: 10;
    
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  
    width: .48em;
    height: .48em;
    
    background: #E4F1EF;
    border-radius: 50%;
  }
`

const moveAnimation = keyframes`
  0% {
    transform: rotate(0);
    opacity: 0;
  }
  12% {
    opacity: 1;
  }
  38% {
    opacity: 1;
  }
  50% {
    transform: rotate(180deg);
    opacity: 0;
  }
  100%{
    opacity: 0;
  }
`

const moveAnimationCompensator = keyframes`
  0% {
    transform: translateX(-50%) rotate(0);
  }
  50% {
    transform: translateX(-50%) rotate(-180deg);
  }
`

const moveAnimationUnder = keyframes`
  0% {
    transform: rotate(0);
    opacity: 0;
  }
  12% {
    opacity: 1;
  }
  38% {
    opacity: 1;
  }
  50% {
    transform: rotate(-180deg);
    opacity: 0;
  }
  100%{
    opacity: 0;
  }
`

const moveAnimationUnderCompensator = keyframes`
  0% {
    transform: translateX(-50%) rotate(0);
  }
  50% {
    transform: translateX(-50%) rotate(180deg);
  }
`

export const ReceiptsIcon = styled(AbstractIcon)`
  transform-origin: 50%;
  animation: ${moveAnimation} linear 7s infinite;
  
  div {
    transform: translateX(-100%);
    transform-origin: center center;
    animation: ${moveAnimationCompensator} linear 7s infinite;
  }
`

export const TagIcon = styled(AbstractIcon)`
  transform-origin: 50%;
  animation: ${moveAnimation} linear 7s infinite;
  animation-delay: 4s;
  
  div {
    transform: translateX(-100%);
    transform-origin: center center;
    animation: ${moveAnimationCompensator} linear 7s infinite;
    animation-delay: 4s;
  }
`

export const PurchaseIcon = styled(AbstractIcon)`
  transform-origin: 50%;
  animation: ${moveAnimationUnder} linear 10s infinite;
  animation-delay: 2s;
  
  div {
    transform: translateX(-100%);
    transform-origin: center center;
    animation: ${moveAnimationUnderCompensator} linear 10s infinite;
    animation-delay: 2s;
  }
`

export const ReceiptIcon = styled(AbstractIcon)`
  transform-origin: 50%;
  animation: ${moveAnimationUnder} linear 9s infinite;
  animation-delay: 6s;
  
  div {
    transform: translateX(-100%);
    transform-origin: center center;
    animation: ${moveAnimationUnderCompensator} linear 9s infinite;
    animation-delay: 6s;
  }
`

export const ToolsIcon = styled(AbstractIcon)`
  transform-origin: 50%;
  animation: ${moveAnimationUnder} linear 6s infinite;
  animation-delay: 1s;
  
  div {
    transform: translateX(-100%);
    transform-origin: center center;
    animation: ${moveAnimationUnderCompensator} linear 6s infinite;
    animation-delay: 1s;
  }
`

// TEXT CONTENT

export const Content = styled.div`
  flex: 1;
`

export const Title = styled.h2`
  font-size: 34px;
  font-weight: bold;
  line-height: 50px;
  
  @media screen and (max-width: 900px) {
    font-size: 30px;
  }
`

export const ContentText = styled(Text)`
  margin-top: 31px;
  max-width: 600px;
`
