import React, { useState } from 'react'

import {
  QualifyBlock,
  QualifyContent,
  QualifyDescription,
  QualifyImage,
  QualifyImageContainer,
  QualifyTitle,
} from './styles'
import GirlWithPhone from 'assets/images/home/girl_with_phone.jpg'
import { Container } from 'components/common/styles'
import Button, { ButtonSize } from 'components/common/button'
import Modal from 'components/common/modal'
import Waitlist from '../common/modal/content/waitlist'
import CompanyType from '../common/enums/company-type'

type Props = {
  headerText?: string
  defaultIndustry?: CompanyType
  image?: string
  locationForEvent?: string
}

const QualifySection: React.FC<Props> = ({ headerText = 'Join our waitlist', image = GirlWithPhone, defaultIndustry, locationForEvent = 'Qualify block' }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <Modal isOpen={isModalOpen} title="Join waitlist" onClose={() => setIsModalOpen(false)}>
        <Waitlist closeModal={() => setIsModalOpen(false)} defaultIndustry={defaultIndustry} />
      </Modal>
      <Container>
        <QualifyBlock>
          <QualifyImageContainer>
            <QualifyImage src={image} />
          </QualifyImageContainer>

          <QualifyContent>
            <QualifyTitle>{headerText}</QualifyTitle>

            <QualifyDescription>
              We’re working hard to revolutionize the corporate card experience. Join our waitlist for priority access
              and be a part of the ComCard launch.
            </QualifyDescription>

            <Button size={ButtonSize.MEDIUM} onClick={() => {
              setIsModalOpen(true)
              window.analytics.track('Open the waitlist modal', {
                location: locationForEvent,
              })
            }}>Join Waitlist</Button>
          </QualifyContent>
        </QualifyBlock>
      </Container>
    </>
  )
}

export default QualifySection
